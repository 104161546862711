<template>
<div>
  <a-drawer
      :title="expertForm.formType==='add'?'新增':expertForm.formType==='edit'?'修改':'简介'"
      :width="600"
      :visible="expertForm.formShow"
      :body-style="{ paddingBottom: '80px' }"
      @close="onClose"
  >
    <a-form-model ref="addItem" :label-col="labelCol" :model="formData" :rules="rules" :wrapper-col="wrapperCol" :validateFirst=true >
      <a-form-model-item label="头像1" prop="headImg" v-if="expertForm.formType !=='resume'">
        <div>
          <div style="display: flex">
            <div class="uploadImg">
              <input
                  id="inputTest"
                  ref="addImg"
                  accept="image/*"
                  style="width: 100%;height:100%;opacity: 0;"
                  type="file"
                  @change="uploadImg('addImg')"/>
            </div>
            <div class="upload_gutter">
              <img
                  v-if="formData.headImg"
                  :src="formData.headImg" 
                  alt=""
                  style="width: 100%;height:100%"/>
            </div>
          </div>
          <span style="font-size: xx-small">注：尺寸200*200，大小不超过500kb</span>
        </div>
      </a-form-model-item>
      <a-form-model-item label="头像2" v-if="expertForm.formType !=='resume'">
        <div>
          <div style="display: flex">
            <div class="uploadImg">
              <input
                  ref="addImgRect"
                  accept="image/*"
                  style="width: 100%;height:100%;opacity: 0;"
                  type="file"
                  @change="uploadImg('addImgRect')"/>
            </div>
            <div class="upload_gutter">
              <img
                  v-if="formData.headImgRect"
                  :src="formData.headImgRect"
                  alt=""
                  style="width: 100%;height:100%"/>
            </div>
          </div>
          <span style="font-size: xx-small">注：尺寸180*230，大小不超过500kb</span>
        </div>
      </a-form-model-item>
      <a-form-model-item label="姓名 " prop="name" v-if="expertForm.formType !=='resume'">
        <a-input v-model="formData.name" placeholder="请输入" />
      </a-form-model-item>
      <a-form-model-item label="省份城市" prop="province" v-if="expertForm.formType !=='resume'">
        <a-cascader
            v-model="formData.province"
            :options="hospitalOptions"
            placeholder="请选择"
            @change="provinceChange"
        />
      </a-form-model-item>
      <a-form-model-item label="类别" prop="type" v-if="expertForm.formType !=='resume'">
        <!-- <a-radio-group :options="plainOptions" :default-value="this.expertForm.type === null?value1:value2" @change="onChange1" /> -->
        <a-radio-group :options="plainOptions" :default-value="value1" @change="onChange1" v-if="this.expertForm.type === null || this.expertForm.type === '医护人员'" />
        <a-radio-group :options="plainOptions" :default-value="value2" @change="onChange1" v-if="this.expertForm.type === '非医护人员'"/>
      </a-form-model-item>

      <!-- 1.类别切换医院 √ -->
      <a-form-model-item label="医院" prop="hospital" v-if="expertForm.formType !=='resume' && this.type !== '非医护人员'">
        <a-select
            v-model="formData.hospital"
            placeholder="请选择"
            show-search
            @popupScroll="popupScroll_questionCode"
            @search="select_hospital"
        >
          <a-select-option
              v-for="item in hospitalNameList"
              :value="item.name">
            {{item.name}}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="单位" prop="hospital" v-if="expertForm.formType !=='resume' && this.type !== '医护人员'">
        <a-input v-model="formData.hospital" auto-size placeholder="请输入" />
      </a-form-model-item>

      <!-- 2.切换科室 -->
      <a-form-model-item label="科室" prop="department" v-if="expertForm.formType !=='resume' && this.type !== '非医护人员'">
        <a-cascader
            v-model="formData.department"
            :options="hospitalOfficesList"
            placeholder="请选择"
        />
      </a-form-model-item>
      <a-form-model-item label="部门" prop="departments" v-if="expertForm.formType !=='resume' && this.type !== '医护人员'">
          <a-input v-model="formData.departments"  auto-size placeholder="请输入" />
      </a-form-model-item>

      <!-- 3.切换职称 √ -->
      <a-form-model-item label="职称"  prop="job" v-if="expertForm.formType !=='resume' && this.type !== '非医护人员'">
        <a-select
            v-model="formData.job"
            placeholder="请选择"
        >
          <a-select-option
              v-for="item in jobTitleList"
              :value="item.jobTitle">
            {{item.jobTitle}}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="职位"  prop="jobs" v-if="expertForm.formType !=='resume' && this.type !== '医护人员'">
        <!-- <a-input v-model="formData.job" auto-size placeholder="请输入" />   -->
        <a-input v-model="formData.jobs" placeholder="请输入"  />
      </a-form-model-item>


      <a-form-model-item label="标签" v-show="expertForm.formType !=='resume'">
        <div>
          <a-input
              v-if="tagsShow"
              ref="input"
              type="text"
              size="small"
              :style="{ width: '78px' }"
              :value="inputValue"
              @change="handleInputChange"
              @blur="handleInputConfirm()"
              @keyup.enter.native="$event.target.blur()"
          />
          <a-tag  v-else style="background: #fff; borderStyle: dashed;" @click="showInput">+标签</a-tag>
        </div>
        <div>
          <a-tag v-for="(list,index) in formData.tags" >{{ list }}<a-icon type="close" @click="delTags(index)"/></a-tag>
        </div>


      </a-form-model-item>

      <a-form-model-item  label="简介"  prop="into" v-if="expertForm.formType =='resume'||expertForm.formType =='add'">
        <a-textarea v-model="formData.into" auto-size placeholder="请输入" style="min-height: 400px;font-size: 16px;-webkit-scrollbar: display:none;overflow:hidden"/>
        <span v-if="expertForm.formType !=='add'" style="color: #8c8a8a;font-size: 12px">简介上次修改时间: {{formData.upUpdatedTime ? formData.upUpdatedTime : formData.createdTime}}</span>
      </a-form-model-item>
    </a-form-model>


    <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
    >
      <a-button :style="{ marginRight: '8px' }" @click="()=>{
        this.expertForm.formShow = false;
        this.formData={
          tags:[],
          hospital:undefined,
        }
      }">
        取消
      </a-button>
      <!-- 提交并更新简介 -->
      <a-popconfirm title="是否确认更新简介" ok-text="是" cancel-text="否" @confirm="updateOk"> 
        <a-button v-show="expertForm.formType =='resume'" :style="{ marginRight: '8px' }" type="primary">
          确认
        </a-button>
      </a-popconfirm>

      <!-- 修改 -->
      <a-button type="primary" @click="addOk" v-show="expertForm.formType =='edit'">
        确认
      </a-button>
      <!-- 新增 -->
      <a-button type="primary" @click="upOk" v-show="expertForm.formType =='add'">
        确认
      </a-button>
    </div>
  </a-drawer>
</div>
</template>

<script>

import City from "@/assets/city.json";
import {getHospital} from "@/service/hospital_api";
import {getTitle2} from "@/service/dataDictionary_api";
import {getLoginDepartment} from "@/service/dataCleaned_api";
import {update} from "@/utils/update";
import {getExpert, getExpertId, getMeetMemberTel, postExpert, putExpert} from "@/service/zoomMeet_api";
import moment from "moment";
const plainOptions = ['医护人员', '非医护人员'];

export default {
  name: "AddExpertModel",
  props:{
    expertForm: Object,
    // type:String
  },
  data(){
    return {
      newDepartment:'',
      plainOptions,
      type:'医护人员',
      value1: '医护人员',  //用与医护类型的默认框
      value2: '非医护人员',
      inputValue:undefined,
      tagsShow:false,
      formShow:false,
      formData:{
        hospital:undefined,
        tags: [],
        department: null,
        province:null
      },
      hospitalPage:{
        name:undefined,
        current: 1,
      },
      labelCol: {span: 4},
      wrapperCol: {span: 16},
      rules: {
        name: [{required: true, message: '请输入姓名', trigger: 'blur'}],
        province: [{required: true, message: '请选择省份/城市', trigger: 'change'}],
        headImg: [{required: true, message: '请上传头像', trigger: 'blur'}],
        hospital: [{required: true, message: '请填入医院/单位', trigger: 'change'}],
        department: [{required: true, message: '请填入科室/部门', trigger: 'change'}],
        into: [{required: true, message: '请输入简介', trigger: 'blur'}],
        job: [{required: true, message: '请填入职称/职位', trigger: 'change'}],
        enclosure: [{required: true, message: '请上传文件', trigger: 'blur'}],
      },
      City:City,
      hospitalOptions:[],
      hospitalNameList:[],
      jobTitleList:[],
      hospitalOfficesList:[],
    }
  },

  watch: {
    expertForm(newVal, oldVal) {
      this.hospitalNameList = []
      this.getPCH()
      this.getHospitalOfficesList()
      this.getJobTitleList()
      if (newVal.formType === 'edit' || newVal.formType === 'resume') {
        this.getExpertData(newVal.editId)
      } else {
        this.getPCH()
        this.formData={
          tags:[],
          hospital:undefined,
        }
      }
      if (this.expertForm.type == null || this.expertForm.type == "医护人员") {
        this.type = "医护人员"
      } else {
        this.type = "非医护人员"
      }
    },

  },
  methods: {
     onChange1(e) {
      this.type = e.target.value
      console.log("状态呢",this.expertForm.formType)
      // 切换的时候清空绑定的值
      this.formData.hospital = undefined
      this.formData.job = undefined
      this.formData.department = undefined
      this.formData.province = null  
    },
    onClose() {
      this.expertForm.formShow = false;
      this.expertForm.type = '';   
      this.$refs.addItem.resetFields()
    },

    //id获取专家数据
    async getExpertData(id){
      const info = await getExpertId(id)
      console.log("总数据", info)
      if (info.code === 0) {
        this.formData = info.data.rows[0]
        // 通过这里赋值给department （department默认为空）
        if (this.type == "医护人员") {
          this.formData.department = info.data.rows[0].hospitalOffice !== null ? info.data.rows[0].hospitalOffice.split('-') : []
          // this.formData.department = info.data.rows[0].hospitalOffice
          console.log("医护人员",this.formData.department)
        } else {
          this.formData.departments = info.data.rows[0].hospitalOffice 
          this.formData.jobs = info.data.rows[0].job
          console.log("数据",this.formData.departments,this.formData.jobs)
        }

        this.formData.province=[this.formData.province,this.formData.city]
        let data = {
          province:this.formData.province[0],
          city:this.formData.province[1],
          name:this.formData.hospital,
          pagenum:1,
          pagesize:10,
        }
        await this.getHosNameList(data)

        if (this.formData.tags===null){
          this.formData.tags=[]
        }else {
          this.formData.tag=[]
          this.formData.tag=this.formData.tags.split(/[，；,;、]/)
          this.formData.tags=[]
          this.formData.tags=this.formData.tag
        }
      }
    },

    // 修改
    addOk() {
      this.$refs.addItem.validate(async valid =>{
        if(valid){
          const user_info = window.localStorage.getItem("user_info");
          const form=this.formData
          switch (this.expertForm.formType && this.type){
            // case 'add':
            //   const addData={
            //     byId:JSON.parse(user_info).id,
            //     headImg:form.headImg,
            //     name:form.name,
            //     hospital:form.hospital,
            //     headImgRect:form.headImgRect,
            //     into:form.into,
            //     tags:form.tags.length===0?null:form.tags.join(","),
            //     province:form.province[0],
            //     city:form.province[1],
            //     job: form.job,
            //     // 导致报错
            //     // department: form.department,
            //     department: form.department[0] + '-' + form.department[1],
            //     // 传参 新增
            //     type:this.type
            //   }
            //   const add = await postExpert(addData)
            //   if (add.code===0){
            //     this.formData={
            //       tags:[],
            //       hospital:undefined,
            //     }
            //     this.$message.success('新增成功')
            //     this.expertForm.formShow=false
            //     //操作成功后，刷新父组件获取数据事件
            //     this.$parent.getLogData(form.name)
            //   }
            //   this.$router.go(0)
            //   break;

              case 'edit' && '医护人员':
              const editData={
                id:form.id,
                byId:JSON.parse(user_info).id,
                headImg:form.headImg,
                name:form.name,
                hospital:form.hospital,
                headImgRect:form.headImgRect,
                into:form.into,
                tags:form.tags.length===0?null:form.tags.join(","),
                province:form.province[0],
                city:form.province[1],
                job: form.job,
                hospitalOffice:form.hospitalOffice,
                department: form.department.join("-"),  //数组方法
                // 传参 修改
                type:this.type
              }
              const edit = await putExpert(editData)
              if (edit.code===0){
                this.formData={
                  tags:[],
                  hospital:undefined,
                }
                this.$message.success('修改成功')
                this.expertForm.formShow=false
                this.$parent.getLogData(form.name)
              }
              break;

               case 'edit' && '非医护人员':
              const feieditData={
                id:form.id,
                byId:JSON.parse(user_info).id,
                headImg:form.headImg,
                name:form.name,
                hospital:form.hospital,
                headImgRect:form.headImgRect,
                into:form.into,
                tags:form.tags.length===0?null:form.tags.join(","),
                province:form.province[0],
                city:form.province[1],
                job: form.jobs,
                hospitalOffice:form.hospitalOffice,
                department: form.departments,  //数组方法
                // 传参 修改
                type:this.type
              }
              console.log("非医护表单数据",feieditData)
              const feiedit = await putExpert(feieditData)
              if (feiedit.code===0){
                this.formData={
                  tags:[],
                  hospital:undefined,
                }
                this.$message.success('修改成功')
                this.expertForm.formShow=false
                this.$parent.getLogData(form.name)
              }
              break;

              case 'resume' &&'医护人员':
              const editDat={
                id:form.id,
                byId:JSON.parse(user_info).id,
                headImg:form.headImg,
                name:form.name,
                hospital:form.hospital,
                headImgRect:form.headImgRect,
                into:form.into,
                tags:form.tags.length===0?null:form.tags.join(","),
                province:form.province[0],
                city:form.province[1],
                job:form.job,
                department:form.department.join("-"),
              }
              const edi = await putExpert(editDat)
              if (edi.code===0){
                this.formData={
                  tags:[],
                  hospital:undefined,
                }
                this.$message.success('修改成功')
                this.expertForm.formShow=false
                this.$parent.getLogData(form.name)
              }
              break;

               case 'resume' &&'非医护人员':
              const editDatc={
                id:form.id,
                byId:JSON.parse(user_info).id,
                headImg:form.headImg,
                name:form.name,
                hospital:form.hospital,
                headImgRect:form.headImgRect,
                into:form.into,
                tags:form.tags.length===0?null:form.tags.join(","),
                province:form.province[0],
                city:form.province[1],
                job:form.job,
                department:form.department.join("-"),
              }
              const edic = await putExpert(editDatc)
              if (edic.code===0){
                this.formData={
                  tags:[],
                  hospital:undefined,
                }
                this.$message.success('修改成功')
                this.expertForm.formShow=false
                this.$parent.getLogData(form.name)
              }
              break;
            
          }
        }
      })


    },
    async upOk() {
       this.$refs.addItem.validate(async valid =>{
        if(valid){
          const user_info = window.localStorage.getItem("user_info");
          const form = this.formData
          switch (this.expertForm.formType && this.type){
            case 'add' && '医护人员':
              const addData={
                byId:JSON.parse(user_info).id,
                headImg:form.headImg,
                name:form.name,
                hospital:form.hospital,
                headImgRect:form.headImgRect,
                into:form.into,
                tags:form.tags.length===0?null:form.tags.join(","),
                province:form.province[0],
                city:form.province[1],
                job: form.job,
                department: form.department[0]+ '-' +form.department[1], 
                // 传参 新增
                type:this.type
              }
              console.log("填写的数据",addData)
              const addresponse = await postExpert(addData)
              if (addresponse.code===0){
                this.formData={
                  tags:[],
                  hospital:undefined,
                }
                this.$message.success('新增成功')
                this.expertForm.formShow=false
                //操作成功后，刷新父组件获取数据事件
                this.$parent.getLogData(form.name)
              }
              // this.$router.go(0)
              break;

               case 'add' && '非医护人员':
              const addyihuData={
                byId:JSON.parse(user_info).id,
                headImg:form.headImg,
                name:form.name,
                hospital:form.hospital,
                headImgRect:form.headImgRect,
                into:form.into,
                tags:form.tags.length===0?null:form.tags.join(","),
                province:form.province[0],
                city:form.province[1],
                job: form.jobs,
                department: form.departments,    //存成数组？？
                // 传参 新增
                type:this.type
              }
              console.log("填写的数据",addyihuData)
              const add = await postExpert(addyihuData)
              if (add.code===0){
                this.formData={
                  tags:[],
                  hospital:undefined,
                }
                this.$message.success('新增成功')
                this.expertForm.formShow=false
                //操作成功后，刷新父组件获取数据事件
                this.$parent.getLogData(form.name)
              }
              break;


              //
          }
        }
      })
    },
    async updateOk() {
      const form = this.formData
      const user_info = window.localStorage.getItem("user_info");
      const editData = {
        id: form.id,
        byId: JSON.parse(user_info).id,
        upUpdatedTime: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),

        // headImg:form.headImg,
        // name:form.name,
        // hospital:form.hospital,
        // headImgRect:form.headImgRect,
        into:form.into,
        // tags:form.tags.length===0?null:form.tags.join(","),
        // province:form.province[0],
        // city:form.province[1],
        // job:form.job,
        // department:form.department,
      }
      console.log("无语啊",editData)
      const edit = await putExpert(editData)
      if(edit.code === 0){
        const info = await getExpertId(form.id)
        this.formData.upUpdatedTime = info.data.rows[0].upUpdatedTime
        this.$parent.getLogData(form.name)
        this.expertForm.formShow = false
        this.$message.success('简介更新成功')
        // console.log(info.data.rows[0].upUpdatedTime)
      }
    },


    //省份城市options
    getPCH(){
      let province = []
      this.City.forEach((item,index)=>{
        let city = []
        province.push({
          value:item.name,
          label:item.name,
          children:city
        })
        item.cityList.forEach((list)=>{
          city.push({
            value:list.name,
            label:list.name,
            loading:false,
          })
        })
        province[index].children = city
      })
      this.hospitalOptions = province
    },
    //医院数据
    async getHosNameList(data) {
      const response = await getHospital(data)
      if(response.code === 0){
        let list = response.data.rows
        for (let i=0;i<list.length;i++){
          this.hospitalNameList.push({
            id: list[i].id,
            name: list[i].name,
          })
        }
      }
    },




    //上传图片
    async uploadImg(type) {
      var inputTest = document.querySelector('#inputTest')
      let inputDOM = this.$refs[type].files[0];
      console.log("为什么啊", this.formData)
      if(inputDOM.size < 512000){
        const image =await update(inputDOM)
        if (image.code === 0){
          switch (type){
            case 'addImg':
              this.$set(this.formData, 'headImg', image.data.url)
              break;
            case 'addImgRect':
              this.$set(this.formData,'headImgRect',image.data.url)
              break;
          }
          this.$message.success('上传成功')
        }
        else {
          this.$message.warning('上传失败'+ image.message)
        }
      }
      else {
        this.$message.warning('仅上传500k内图片')
      }
      inputTest.value =''
    },


    //滚动条监听
    popupScroll_questionCode(e){
      const {target} = e
      const scrollHeight = target.scrollHeight - target.scrollTop //滑动总高度
      const clientHeight = target.clientHeight //滑动最低高度
      if(scrollHeight < clientHeight + 2){
        this.hospitalPage.current++
        let values = this.formData.province
        const data={
          province:values[0],
          city:values[1],
          name:this.hospitalPage.name,
          pagenum:this.hospitalPage.current,
          pagesize:10,
        }
        this.getHosNameList(data)
        // this.getExamModel()
      }
    },
    //医院搜索
    select_hospital(value){
      this.hospitalNameList = []
      this.hospitalPage.name=value
      let values = this.formData.province
      const data={
        province:values[0],
        city:values[1],
        name:value,
        pagenum:1,
        pagesize:10,
      }
      this.getHosNameList(data)
    },

    //省份城市数据
    provinceChange(value){
      this.hospitalNameList = []
      this.formData.hospital = undefined
      let data = {
        province:value[0],
        city:value[1],
        pagenum:1,
        pagesize:10,
      }
      this.getHosNameList(data)
    },
    async getJobTitleList(identify) {
      const response = await getTitle2(identify)
      if (response.code === 0) {
        this.jobTitleList=response.data.row
      }
    },
    //获取科室列表
    async getHospitalOfficesList() {
      const response = await getLoginDepartment()
      if(response.code === 0){
        let oneOff = []
        let list = response.data.wySy
        list.forEach((item,index)=>{
          let twoOff = []
          oneOff.push({
            value:item.title,
            label:item.title,
            children:twoOff
          })
          item.zoffices.forEach((lists)=>{
            twoOff.push({
              value:lists.title,
              label:lists.title,
            })
          })
          oneOff[index].children = twoOff
        })
        this.hospitalOfficesList = oneOff
      }
    },
    //新增标签
    showInput() {
      this.tagsShow = true;
      this.$nextTick(function() {
        this.$refs.input.focus();
      });
    },
    handleInputChange(e) {
      this.inputValue = e.target.value;
    },

    handleInputConfirm() {
      if (this.inputValue!==''){
        this.formData.tags.push(this.inputValue)
        this.tagsShow = false;
        this.inputValue=''
      }else {
        this.$message.warning('请输入内容')
      }
    },
    delTags(index){
      this.formData.tags.splice(index, 1)
    },
  }
}
</script>

<style scoped>
.uploadImg{
  border: #DDDDDD 2px dashed;
  width: 120px;
  height: 120px;
  background-image: url("../assets/add.png");
  background-size:100% 100%;
  background-repeat:no-repeat;
  cursor: pointer;
}
.upload_gutter{
  margin-left: 10px;
  width: 200px;
}
</style>
